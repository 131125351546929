import React from "react";
import * as styles from './skeleton-loader.module.css';

export default function SkeletonLoaderItem({ children, className, height, width }) {


    return <div className={`${styles.skeletonLoader__item} ${className}`} style={{ height, width }}>
        { children }
    </div>

}