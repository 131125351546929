import React, { useEffect } from "react";
import { useContext } from "react";
import CommerceContext from '../../../context/commerce-context';
import * as style from './cart-sidebar.module.css';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Btn from '../../material/btn/btn';
import CartLines from "./cart-lines";
import CartSummary from "./cart-summary";
import CartCheckout from "./cart-checkout";
import ResponsiveContext from "../../../context/responsive-context";
import Overlay from "../../material/overlay/overlay";

export default function CartSidebar() {
    const { state, dispatch } = useContext(CommerceContext);
    const { isMobile } = useContext(ResponsiveContext);
    const closeSidebar = () => dispatch({ type: 'sidebar.close' });
    useEffect(() => {
        if (isMobile) document.body.style.overflowY = state.sidebarIsVisible ? 'hidden' : 'visible'
    }, [isMobile, state.sidebarIsVisible]);

    return <TransitionGroup>

        <Overlay isActive={state.sidebarIsVisible} onClick={closeSidebar} />

        { state.sidebarIsVisible && <CSSTransition timeout={500} classNames={{
            enter: style.sidebarEnter,
            enterActive: style.sidebarEnterActive,
            exit: style.sidebarExit,
            exitActive: style.sidebarExitActive,
        }}>
            <div className={style.sidebar}>
                <div className="flex justify-between my-4 flex-col md:flex-row">
                    <div className="text-2xl">Your Cart</div>
                    <Btn
                        onClick={closeSidebar}
                        className="h-8 bg-primary text-white"
                        icon="bi-cart-plus-fill"
                        label="Keep Shopping"
                    />
                </div>
                <CartLines lines={state.cart.lines}/>
                <div className="flex-grow" />
                <CartSummary />
                <div className="h-6" />
                <CartCheckout />
                <div className="h-2" />
            </div>
        </CSSTransition> }

    </TransitionGroup>
}