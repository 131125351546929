import React from "react";
import CartSidebar from "../commerce/cart/cart-sidebar";
import { Footer } from "./footer";
import { Header } from "./header";
import * as styles from './layout.module.css';

export function Layout({
    children,
    fill = false
}) {

    return <div id="layout-container">
        <CartSidebar />
        <Header passthrough={fill} />
        <div className={`${styles.contentContainer} ${fill ? styles.contentContainerFilled : styles.contentContainerGuttered }`}>
            {children}
        </div>
        <Footer />
    </div>

}
