import React, { useState, useEffect } from "react";
import * as styles from './counter.module.css';

export default function Counter({ min, max, value, onChange }) {
    const [currentValue, setCurrentValue] = useState(value);
    
    useEffect(() => onChange(currentValue), [currentValue]);
    
    const handleChange = (newValue) => {
        if (newValue === currentValue) return;
        setCurrentValue(newValue);
    }

    const increment = () => {
        if ((max === null || max === undefined) || currentValue < max) handleChange(currentValue + 1);
    }
    const decrement = () => {
        if ((min === null || min === undefined) || currentValue > min) handleChange(currentValue - 1);
    }

    return <span className={styles.field}>
        <div onClick={decrement} className={styles.field__button}>
            <i className={`bi bi-dash`} />
        </div>
        <div className={styles.field__textInput}>
            { currentValue }
        </div>
        <div onClick={increment} className={styles.field__button}>
            <i className={`bi bi-plus ${styles.field__button}`} />
        </div>
    </span>

}