import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import SocialLinks from '../social/links';
import * as styles from './footer.module.css';

const LINKS_QUERY = graphql`query {
    allBuilderModels {
        footerLinks {
            id
            data {
                external
                url
                label
                section
            }
        }
        footerSections {
            id
            data {
                label
            }
        }
    }
}`;

export function Footer() {
    const data = useStaticQuery(LINKS_QUERY);
    const sections = (data?.allBuilderModels?.footerSections ?? [])
        .map(section => {
            const links = (data?.allBuilderModels?.footerLinks ?? [])
                .filter(link => link.data.section != null && section.id === link.data.section.id)
                .map(link => {
                    const common = { className: 'block', key: link.id };
                    return link.data.external
                        ? <a href={link.data.url} target="_blank" rel="noreferrer" {...common}> { link.data.label } </a>
                        : <Link to={link.data.url} {...common}> { link.data.label } </Link>
                });

            return <div className={styles.footerLinkSection} key={section.id}>
                <span className='text-lg block text-primary'>{ section.data.label }</span>
                { links }
            </div>
        });

    return <footer className={styles.footer}>
        <div className={styles.footerLinkSectionContainer}>
            { sections }
        </div>
        <div className={styles.footerBoot}>
            <span></span>
            <span>© {new Date().getFullYear()} Purpose Front LLC.</span>
            <SocialLinks className="text-lg px-4" />
        </div>
    </footer>
}