import React, { useState, useEffect } from "react";
import { useContext } from "react";
import CommerceContext from "../../../context/commerce-context";
import Counter from "../../material/counter/counter";

export default function CartLineQuantity({ line, delay, onRequest, onSuccess, onError }) {
    const [quantity, setQuantity] = useState(line.quantity);
    const { updateCartLine } = useContext(CommerceContext);

    useEffect(() => {
        if (quantity === line.quantity) return;
        const updateQuantity = async () => {
            if (onRequest != null) onRequest();
            try {
                await updateCartLine(line.id, quantity);
                if (onSuccess != null) onSuccess();
            } catch (e) {
                if (onError != null) onError(e);
            }
        }
        const timeout = setTimeout(updateQuantity, delay ?? 2000);
        return () => clearTimeout(timeout);
    }, [quantity]);

    let quantityAvailable;
    if (line.availableForSale === false) {
        quantityAvailable = 0;
    } else if (line.quantityAvailable === 0) {
        quantityAvailable = null; // If it is available but quantity is zero, then inventory is not being tracked.
    } else {
        quantityAvailable = line.quantityAvailable;
    }

    return <Counter
        min={0}
        max={quantityAvailable}
        value={quantity}
        onChange={setQuantity}
    />
}