import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import * as styles from './overlay.module.css';

export default function Overlay({ isActive, onClick, children, siblings, persistent }) {
    return <TransitionGroup>
        { siblings }
        { isActive && <CSSTransition timeout={500} classNames={{
            enter: styles.backdropEnter,
            enterActive: styles.backdropEnterActive,
            exit: styles.backdropExit,
            exitActive: styles.backdropExitActive,
        }}>
            <div className={styles.backdrop} onClick={onClick}> {children} </div>
        </CSSTransition> }
    </TransitionGroup>
}