import React from "react";
import { useContext } from "react";
import CommerceContext from "../../../context/commerce-context";
import { formatPrice } from "../../../utils/format-price";
import Divet from "../../material/divet/divet";

export default function CartSummary() {
    const { state } = useContext(CommerceContext);
    const cost = state.cart.cost;

    return <div>
        <Divet>
            <div className="flex justify-between">
                <span className="font-medium">Subtotal</span>
                <span>{ cost.subtotalAmountEstimated && cost.subtotalAmount != null
                    ? formatPrice(cost.subtotalAmount.currencyCode, cost.subtotalAmount.amount)
                    : 'calculated at checkout' }
                </span>
            </div>
            <div className="flex justify-between">
                <span className="font-medium">Shipping</span>
                <span>calculated at checkout</span>
            </div>
            <div className="flex justify-between">
                <span className="font-medium">Taxes</span>
                <span>{ cost.totalTaxAmountEstimated && cost.totalTaxAmount !== null
                    ? formatPrice(cost.totalTaxAmount.currencyCode, cost.totalTaxAmount.amount)
                    : 'calculated at checkout' }
                </span>
            </div>
        </Divet>
        <div className="mx-4 mt-2 flex justify-between">
            <span className="font-bold">Estimated Total</span>
            <span>{ cost.totalAmountEstimated && cost.totalAmount != null
                ? formatPrice(cost.totalAmount.currencyCode, cost.totalAmount.amount)
                : 'calculated at checkout' }
            </span>
        </div>
    </div>
}