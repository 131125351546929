import React from "react";
import * as styles from './spinner.module.css'

export default function Spinner({ className, children }) {
    return <span className={`${styles.spinner} ${className}`}>
        { children
            ? children
            : <i className={`bi bi-arrow-through-heart ${styles.spinner__icon}`} />
        }
    </span>
}