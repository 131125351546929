import React, { useContext } from "react"
import { Link } from "gatsby"
import Logo from "../../icons/logo"
import ResponsiveContext from '../../context/responsive-context'
import * as styles from "./header.module.css"
import CartBtn from "../commerce/cart/cart-btn"
import NavigationRow from "./navigation/row"
import NavigationHamburger from "./navigation/hamburger"
import { NavigationContextProvider } from "../../context/navigation-context"

export function Header({ passthrough = false }) {
    const { isMobile } = useContext(ResponsiveContext);

    let content;

    if (isMobile) content = <>
        <NavigationHamburger className="text-2xl text-primary"  />
        <Link to="/" className={styles.logo}>
            <Logo />
        </Link>
        <CartBtn className="text-2xl text-primary" />
    </>
    else content = <>
        <Link to="/" className={styles.logo}>
            <Logo />
        </Link>
        <div className={styles.pillContainer}>
            <NavigationRow />
            <div className={styles.pill}>
                <CartBtn />
            </div>
        </div>
    </>

    return <div className={`${styles.container} ${passthrough && styles.containerPassthrough}`}>
        <NavigationContextProvider>
            <header className={styles.header}>
                {content}
            </header>
        </NavigationContextProvider>
    </div>
}
