import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../../material/overlay/overlay';
import * as styles from './hamburger.module.css';
import { useContext } from 'react';
import NavigationContext from '../../../context/navigation-context';
import CMSLink from '../../cms/link';

export default function NavigationHamburger({ className }) {
    const [isOpen, setOpen] = useState(false);
    const closeMenu = () => setOpen(false);
    const openMenu = () => setOpen(true);

    const { navLinks } = useContext(NavigationContext);

    const items = navLinks.map((link, i) => <CMSLink key={i} {...link} className={styles.navLink} activeClassName={styles.navLinkActive} /> );
    const overlay = <Overlay isActive={isOpen} onClick={closeMenu}>
        <div className={styles.menuDialog}>
            <div className={styles.menuActions}>
                <i className="bi bi-x-lg" onClick={closeMenu}></i>
            </div>
            { items }
        </div>
    </Overlay>
    const layoutContainerEl = document.getElementById('layout-container');
    const overlayPortal = createPortal(overlay, layoutContainerEl);

    return <>
        { overlayPortal }
        <div onClick={openMenu} className={`cursor-pointer flex align-middle ${className}`}>
            <i className="bi bi-list"/>
        </div>
    </>
}