import React from 'react'
import { useContext } from 'react'
import CommerceContext from '../../../context/commerce-context'


export default function CartLineRemove({ line, onRequest, onSuccess, onError }) {
    const { removeCartLine } = useContext(CommerceContext);
    const remove = async () => {
        if (onRequest) onRequest();
        try {
            const result = await removeCartLine(line.id);
            if (onSuccess) onSuccess(result);
        } catch (e) {
            if (onError) onError(e);
        }
    }

    return <i onClick={remove} className="cursor-pointer text-red-500 bi bi-trash-fill"></i>
}