import * as React from 'react';


export default function SocialLinks(props) {

    return <span {...props}>
        <a target='_blank' href='https://www.instagram.com/purposefront' rel='noreferrer'>
            <i className="bi bi-instagram"/>
        </a>
    </span>;

}