import React from "react";
import { useContext } from "react";
import CommerceContext from "../../../context/commerce-context";
import Btn from "../../material/btn/btn";
import PaymentMethods from "../../../icons/payment-methods"

export default function CartCheckout() {
    const { state } = useContext(CommerceContext);
    const url = state.cart.checkoutUrl;
    const hasNoLines = state.cart.lines.length === 0;

    return <div>
        <Btn
            className="bg-gray-700 h-14 text-white text-2xl"
            icon="bi-shield-lock-fill"
            href={url}
            disabled={hasNoLines}
            label="Secure Checkout"
        />
        <PaymentMethods className="mt-2 w-2/3" />
    </div>
}