import React, { useContext } from 'react';
import NavigationContext from '../../../context/navigation-context';
import CMSLink from '../../cms/link';
import * as styles from './row.module.css';

export default function NavigationRow() {
    const { navLinks } = useContext(NavigationContext); 
    const links = navLinks.map((link, i) => <CMSLink key={i} className='px-2' activeClassName={styles.navRowLinkActive} {...link} />);

    return <div className={styles.navRow}>
        { links }
    </div>
}