import { Link } from 'gatsby';
import React from 'react';
import { useState } from 'react';
import { formatPrice } from '../../../utils/format-price';
import Badge from '../../material/badge/badge';
import SkeletonLoader from '../../material/skeleton-loader/skeleton-loader';
import SkeletonLoaderItem from '../../material/skeleton-loader/skeleton-loader-item';
import Spinner from '../../material/spinner/spinner';
import Price from '../price/price';
import CartLineQuantity from './cart-line-quantity';
import CartLineRemove from './cart-line-remove';

export default function CartLine({ line }) {
    const merchandise = line.merchandise;
    const product = line.merchandise.product;
    const image = line.merchandise.image != null && <img src={merchandise.image.url} alt={merchandise.image.alt} width="100px" style={{ objectFit: 'contain' }} max-height="100px" className='rounded-md' />
    const productPageUrl = `/products/${product.handle}`;

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleRequest = () => {
        setError(null);
        setLoading(true);
    }
    
    const handleError = (e) => {
        setError(e);
        console.error(e);
        setLoading(false);
    }

    const handleSuccess = () => {
        setLoading(false);
    }

    const skeleton = (
        <div className='flex align-center my-4'>
            <SkeletonLoaderItem className='mr-3' height='100px' width='100px' />
            <div className='w-full'>
                <SkeletonLoaderItem className='h-5 w-3/4 mb-2' />
                <div className='h-full flex justify-between align-bottom'>
                    <span className='text-sm text-gray-800'>
                        <SkeletonLoaderItem height='1.2rem' width='60px' />
                        <SkeletonLoaderItem className='mt-2' height='36px' width='96px' />
                    </span>
                    <SkeletonLoaderItem className='h-4 w-6' />
                </div>
            </div>
        </div>
    );

    const meat = (
        <div className='flex align-center my-4' >
            <div className='flex align-center mr-3'>
                <Link className='flex align-center' to={productPageUrl}>
                    { image }
                </Link>
            </div>
            <div className='w-full'>
                <div className='text-lg mb-2'>
                    <a href={productPageUrl}>
                    { product.title }
                    </a>
                </div>
                <div className='flex justify-between'>
                    <span className='text-sm text-gray-800'>
                        <a className='mr-2' href={productPageUrl}>
                            <Badge>{ merchandise.title }</Badge>
                        </a>
                        <CartLineRemove line={line} onRequest={handleRequest} onError={handleError} onSuccess={handleSuccess}/>
                        &nbsp;
                        <div className='mt-2'>
                        <CartLineQuantity
                            line={line}
                            delay={1500}
                            onRequest={handleRequest}
                            onSuccess={handleSuccess}
                            onError={handleError}
                        />
                            { loading && <Spinner className='text-purple-500' /> }
                        </div>
                    </span>
                    <div className='self-end'>
                        <Price price={merchandise.price} comparisonPrice={merchandise.compareAtPrice} />
                    </div>
                </div>
            </div>
        </div>
    );

    return <>
        <SkeletonLoader loading={loading} fallback={skeleton}>
            { meat }
        </SkeletonLoader>
        { error != null && <div className="text-red-500">
            Utt-oh! It looks like we couldn't update your cart. Please try again!
        </div> }
    </>

}