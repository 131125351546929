import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useEffect, useState } from 'react';

const COLLECTION_QUERY = graphql`
query {
  allShopifyCollection {
    nodes {
      handle
      id
      description
      title
      image {
        gatsbyImageData
      }
      metafields {
        key
        value
      }
    }
  }
}`;

const DEFAULT = {
    navLinks: [
        {
            label: 'Home',
            url: '/',
            external: false
        }
    ]
};
const NavigationContext = createContext(DEFAULT);

export function NavigationContextProvider({ children }) {

    
    const shopifyCollectionsQuery = useStaticQuery(COLLECTION_QUERY);
    const [collectionLinks, setCollectionLinks] = useState([]);
    useEffect(() => {
        const shopifyCollections = shopifyCollectionsQuery.allShopifyCollection.nodes
            .filter(col => col.metafields.find(field => field.key === 'hidden')?.value != 'true')
        const links = shopifyCollections.map(col => ({
            label: col.title,
            url: `/collections/${col.handle}`,
            external: false
        }));
        setCollectionLinks(links);
    }, [shopifyCollectionsQuery.allShopifyCollection.nodes]);
    
    
    const data = {
        ...DEFAULT,
        navLinks: [...DEFAULT.navLinks, ...collectionLinks]
    };
    
    return <NavigationContext.Provider value={data}>
        { children }
    </NavigationContext.Provider>
}

export default NavigationContext;