import React from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import CommerceContext from '../../../context/commerce-context';

export default function CartBtn({ className }) {
    const { state, dispatch } = useContext(CommerceContext);
    const openSidebar = () => dispatch({ type: 'sidebar.open' });
    
    const quantity = useMemo(
        () => state.cart != null && state.cart.lines.length > 0 && state.cart.lines.reduce((carry, line) => line.quantity + carry, 0),
        [state.cart]
    );

    return <div className={`cursor-pointer flex align-middle ${className}`} onClick={openSidebar}>
        <i className="bi bi-cart-fill"/>
        { quantity && <span className='ml-1'> { quantity } </span> }
    </div>
}